import React from 'react'

export const EventsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17">
    <path
      fillRule="evenodd"
      d="M17 13.031h-5.528l1.203 1.208a1.04 1.04 0 0 1 0 1.466 1.03 1.03 0 0 1-1.461 0l-2.92-2.932a1.022 1.022 0 0 1-.272-.891 1 1 0 0 1 .274-.635l2.939-2.95a1.036 1.036 0 0 1 1.469 0 1.045 1.045 0 0 1 0 1.475L11.481 11H17a1 1 0 0 1 1 1v.031a1 1 0 0 1-1 1zM9.676 4.755l-2.912 2.92a1.027 1.027 0 0 1-1.455 0 1.034 1.034 0 0 1 0-1.46L6.519 5H1a1 1 0 0 1 0-2h5.51L5.28 1.765a1.04 1.04 0 0 1 0-1.469 1.032 1.032 0 0 1 1.464 0l2.93 2.939c.2.201.29.464.291.727.002.036.005.069.004.104-.002.019-.008.036-.01.054a1.02 1.02 0 0 1-.283.635z"
    />
  </svg>
)
