import React from 'react'

export const IconGithub = () => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M14.928 4.084a8.067 8.067 0 0 0-2.912-2.985A7.673 7.673 0 0 0 8-.001a7.67 7.67 0 0 0-4.016 1.1 8.06 8.06 0 0 0-2.912 2.985C.356 5.34-.001 6.712-.001 8.201c0 1.787.508 3.394 1.526 4.821 1.017 1.428 2.332 2.416 3.943 2.964.188.035.327.01.417-.075a.422.422 0 0 0 .135-.32l-.005-.577c-.003-.363-.005-.68-.005-.95l-.24.042a2.985 2.985 0 0 1-.578.038 4.418 4.418 0 0 1-.724-.075 1.6 1.6 0 0 1-.698-.32 1.359 1.359 0 0 1-.458-.657l-.105-.246a2.64 2.64 0 0 0-.328-.544c-.149-.2-.3-.335-.453-.406l-.073-.053a.78.78 0 0 1-.135-.129.572.572 0 0 1-.094-.15c-.021-.049-.004-.09.052-.122.056-.032.156-.048.302-.048l.209.032c.138.028.31.113.515.256.205.142.374.328.506.555.159.292.352.515.578.668.225.153.453.229.682.229.229 0 .427-.017.594-.053.167-.036.323-.089.469-.16.062-.477.232-.844.51-1.1a6.938 6.938 0 0 1-1.068-.193 4.188 4.188 0 0 1-.979-.416 2.828 2.828 0 0 1-.839-.716c-.222-.284-.404-.658-.547-1.121-.142-.463-.213-.997-.213-1.602 0-.862.274-1.595.823-2.2-.257-.648-.233-1.374.073-2.179.201-.064.5-.016.896.144.396.161.686.298.87.412.184.113.332.21.443.288a7.225 7.225 0 0 1 2-.278c.687 0 1.354.093 2.001.278l.395-.256a5.53 5.53 0 0 1 .959-.47c.368-.142.649-.182.844-.117.312.804.34 1.53.083 2.178.549.605.823 1.338.823 2.2 0 .605-.071 1.141-.213 1.607-.143.467-.327.84-.553 1.122a2.926 2.926 0 0 1-.843.71 4.194 4.194 0 0 1-.98.416c-.316.086-.672.15-1.068.193.362.32.542.826.542 1.516v2.253c0 .129.044.235.13.321.087.085.224.11.412.074 1.611-.548 2.926-1.536 3.943-2.963 1.018-1.427 1.526-3.035 1.526-4.822 0-1.488-.358-2.86-1.073-4.116z"
      />
    </svg>
  )
}
