var HOME_NATIVE_TO_ERC_ABI = require('../contracts/build/contracts/HomeBridgeNativeToErc').abi;

var FOREIGN_NATIVE_TO_ERC_ABI = require('../contracts/build/contracts/ForeignBridgeNativeToErc').abi;

var HOME_ERC_TO_ERC_ABI = require('../contracts/build/contracts/HomeBridgeErcToErc').abi;

var FOREIGN_ERC_TO_ERC_ABI = require('../contracts/build/contracts/ForeignBridgeErc677ToErc677').abi;

var HOME_ERC_TO_NATIVE_ABI = require('../contracts/build/contracts/HomeBridgeErcToNative').abi;

var FOREIGN_ERC_TO_NATIVE_ABI = require('../contracts/build/contracts/ForeignBridgeErcToNative').abi;

var ERC20_ABI = require('../contracts/build/contracts/ERC20').abi;

var ERC677_ABI = require('../contracts/build/contracts/ERC677').abi;

var ERC677_BRIDGE_TOKEN_ABI = require('../contracts/build/contracts/ERC677BridgeToken').abi;

var BLOCK_REWARD_ABI = require('../contracts/build/contracts/IBlockReward').abi;

var BRIDGE_VALIDATORS_ABI = require('../contracts/build/contracts/BridgeValidators').abi;

var REWARDABLE_VALIDATORS_ABI = require('../contracts/build/contracts/RewardableValidators').abi;

var _require = require('./v1Abis'),
    HOME_V1_ABI = _require.HOME_V1_ABI,
    FOREIGN_V1_ABI = _require.FOREIGN_V1_ABI;

var _require2 = require('./constants'),
    BRIDGE_MODES = _require2.BRIDGE_MODES;

var ERC20_BYTES32_ABI = [{
  constant: true,
  inputs: [],
  name: 'name',
  outputs: [{
    name: '',
    type: 'bytes32'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}, {
  constant: true,
  inputs: [],
  name: 'symbol',
  outputs: [{
    name: '',
    type: 'bytes32'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}];

function getBridgeABIs(bridgeMode) {
  var HOME_ABI = null;
  var FOREIGN_ABI = null;

  if (bridgeMode === BRIDGE_MODES.NATIVE_TO_ERC) {
    HOME_ABI = HOME_NATIVE_TO_ERC_ABI;
    FOREIGN_ABI = FOREIGN_NATIVE_TO_ERC_ABI;
  } else if (bridgeMode === BRIDGE_MODES.ERC_TO_ERC) {
    HOME_ABI = HOME_ERC_TO_ERC_ABI;
    FOREIGN_ABI = FOREIGN_ERC_TO_ERC_ABI;
  } else if (bridgeMode === BRIDGE_MODES.ERC_TO_NATIVE) {
    HOME_ABI = HOME_ERC_TO_NATIVE_ABI;
    FOREIGN_ABI = FOREIGN_ERC_TO_NATIVE_ABI;
  } else if (bridgeMode === BRIDGE_MODES.NATIVE_TO_ERC_V1) {
    HOME_ABI = HOME_V1_ABI;
    FOREIGN_ABI = FOREIGN_V1_ABI;
  } else {
    throw new Error("Unrecognized bridge mode: ".concat(bridgeMode));
  }

  return {
    HOME_ABI: HOME_ABI,
    FOREIGN_ABI: FOREIGN_ABI
  };
}

module.exports = {
  getBridgeABIs: getBridgeABIs,
  HOME_NATIVE_TO_ERC_ABI: HOME_NATIVE_TO_ERC_ABI,
  FOREIGN_NATIVE_TO_ERC_ABI: FOREIGN_NATIVE_TO_ERC_ABI,
  HOME_ERC_TO_ERC_ABI: HOME_ERC_TO_ERC_ABI,
  FOREIGN_ERC_TO_ERC_ABI: FOREIGN_ERC_TO_ERC_ABI,
  HOME_ERC_TO_NATIVE_ABI: HOME_ERC_TO_NATIVE_ABI,
  FOREIGN_ERC_TO_NATIVE_ABI: FOREIGN_ERC_TO_NATIVE_ABI,
  ERC20_ABI: ERC20_ABI,
  ERC677_ABI: ERC677_ABI,
  ERC677_BRIDGE_TOKEN_ABI: ERC677_BRIDGE_TOKEN_ABI,
  BLOCK_REWARD_ABI: BLOCK_REWARD_ABI,
  BRIDGE_VALIDATORS_ABI: BRIDGE_VALIDATORS_ABI,
  REWARDABLE_VALIDATORS_ABI: REWARDABLE_VALIDATORS_ABI,
  HOME_V1_ABI: HOME_V1_ABI,
  FOREIGN_V1_ABI: FOREIGN_V1_ABI,
  ERC20_BYTES32_ABI: ERC20_BYTES32_ABI
};