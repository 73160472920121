var homeV1Abi = [{
  constant: true,
  inputs: [],
  name: 'validatorContract',
  outputs: [{
    name: '',
    type: 'address'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    name: 'recipient',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }],
  name: 'Deposit',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    name: 'recipient',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }, {
    indexed: false,
    name: 'transactionHash',
    type: 'bytes32'
  }],
  name: 'Withdraw',
  type: 'event'
}, {
  constant: true,
  inputs: [],
  name: 'deployedAtBlock',
  outputs: [{
    name: '',
    type: 'uint256'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}];
var foreignViAbi = [{
  constant: true,
  inputs: [],
  name: 'validatorContract',
  outputs: [{
    name: '',
    type: 'address'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    name: 'recipient',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }, {
    indexed: false,
    name: 'transactionHash',
    type: 'bytes32'
  }],
  name: 'Deposit',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    name: 'recipient',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }, {
    indexed: false,
    name: 'homeGasPrice',
    type: 'uint256'
  }],
  name: 'Withdraw',
  type: 'event'
}, {
  constant: true,
  inputs: [],
  name: 'deployedAtBlock',
  outputs: [{
    name: '',
    type: 'uint256'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}, {
  constant: true,
  inputs: [],
  name: 'erc677token',
  outputs: [{
    name: '',
    type: 'address'
  }],
  payable: false,
  stateMutability: 'view',
  type: 'function'
}];
module.exports = {
  HOME_V1_ABI: homeV1Abi,
  FOREIGN_V1_ABI: foreignViAbi
};